import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import DNSRecords from './DNSRecords';
import AddDomain from './AddDomain';
import GetProfile from './GetProfile';
import Login from './Login';
 import { CookiesProvider } from 'react-cookie';
//import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
ReactDOM.render(
  <React.StrictMode>
 <CookiesProvider>
    <Router>
          <Switch>
		    <Route  path="/drecs" component={DNSRecords} />
		    <Route  path="/adddomain" component={AddDomain} />
			<Route  path="/login" component={Login} />
			<Route  path="/profile" component={GetProfile} />
		  </Switch>
        </Router>
 </CookiesProvider>		
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
