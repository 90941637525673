import React, { Component } from "react";
import axiosInstance from "./utils/axios";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class Login extends Component {
	 static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
	constructor(props)  {
		super(props);
		this.state={
			headerTitle:{fontSize:"46px",fontFamily: "Arial", textAlign:"center"},
			submitStatus:true,
			emailPh:"xjilani@gmail.com",
			password:"Aa@12345678"
			
			
		};
	 }
	 inputLogin = (e) => {
		e.preventDefault();
		alert("Login");
		let formData = new FormData();
		formData.append("emailPh", this.state.emailPh);
		formData.append("password", this.state.password);
		var object ="vi=2";
		formData.forEach((value, key) => object=object+"&"+key+"="+value);
		axiosInstance
				//.post("http://147.182.216.70:5001/action/check/vlogin", object, {
				.post("http://127.0.0.1:5001/action/check/vlogin", object, {	
						headers: {
						  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
						  },
						  "withCredentials": true
				})
			  .then((response) => { 
					console.log("Respond:");
					
					let res=response.data;
					if(res.status===1){
						console.log("Success");
						const { cookies } = this.props;
						
						cookies.set('token', res.resData.token, { path: '/' });
						cookies.set('sid', res.resData.sid, { path: '/' }); 
						cookies.set('Name', res.resData.fullName, { path: '/' });
						cookies.set('Email', res.resData.email, { path: '/' });
						cookies.set('CountryCode', res.resData.countryCode, { path: '/' });
						document.location="./#/profile";
					}
					else if(res.status===0){
						console.log("Invalid");
					}
					else if(res.status===-1){
						console.log("Input Error");
					}
					else if(res.status===-9){
						console.log("Critical");
					}
					else console.log("Critical");
			  }
			  )
			  .catch((error) => { 
			    console.log("Error:");	
				console.log(error);	
				}
			 );
	 
	 
	 }
	 
	
	
	 render()
	{
		return(
		<div className="container">
		 <div className="row mt-4">
			<h1>Login Demo for Brothers</h1>
		 </div>
		 <div className="row mt-4"> </div>
			<button type="Button" className="btn btn-primary pull-left" onClick={this.inputLogin}>Login</button>
		<div className="row mt-4"> </div>
			{this.state.token}
		
		  
		</div>
		);
	}
};

export default withCookies(Login);