import React, { Component } from "react";
import axiosInstance from "./utils/axios";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
class Profile extends Component {
	 static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
	constructor(props)  {
		super(props);
		 const { cookies } = props;
		this.state={
			headerTitle:{fontSize:"46px",fontFamily: "Arial", textAlign:"center"},
			token:cookies.get('token') || '',
			
			
		};
	 }
	
	 
	 fetchProfile = (e) => {
		e.preventDefault();
		alert("Get Profile");
		let formData = new FormData();
		
		formData.append("token", this.state.token);
		var object ="vi=2";
		formData.forEach((value, key) => object=object+"&"+key+"="+value);
		axiosInstance
				//.post("http://147.182.216.70:5001/action/profile/get", object, {
				.post("http://127.0.0.1:5001/action/profile/get", object, {
						headers: {
						  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
						 
						 },
						"withCredentials": true 
						
				})
			  .then((response) => { 
					console.log("Respond:");
					console.log(response);	
			  }
			  )
			  .catch((error) => { 
			    console.log("Error:");	
				console.log(error);	
				}
			 );
	 
	 
	 }
	 
	
	 render()
	{
		return(
		<div className="container">
		 <div className="mt-4"> </div>
			<h1>Brother's Profile</h1>
		
		 <div className="mt-4"></div>
			
			<button type="Button" className="btn btn-success pull-left" onClick={this.fetchProfile}>Get Profile</button>
				Token:{this.state.token}
		 <div className="mt-4"></div>
			
		 
		  
		</div>
		);
	}
};

export default withCookies(Profile);