import React, { Component } from "react";
import {Alert,Nav} from "react-bootstrap";
import axiosInstance from "./utils/axios";
import waitGif from "./timer.gif";
class DNSRecords extends Component {
  
  
	
	 constructor(props)  {
		super(props);
		this.state={
			headerTitle:{fontSize:"46px",fontFamily: "Arial", textAlign:"center"},
			domainName:'',
			error:null,
			submitStatus:true,
			dnsData:null
		};
	 }
	 submitHandler = (e) => {
		e.preventDefault();
		let formData = new FormData();
		formData.append("domain", this.state.domain);
		formData.append("type", "ANY");
		/*var object = {};
		formData.forEach((value, key) => object[key] = value);
		var jsonData = JSON.stringify(object); console.log(jsonData);*/
		var object ="vi=2";
		formData.forEach((value, key) => object=object+"&"+key+"="+value);
		console.log(object);
		this.setState({dnsData:null});
		this.setState({submitStatus:false});
		//.post("http://10.160.0.4/action/get/dnsrecords", object, {
		 axiosInstance
				.post("./action/get/dnsrecords", object, {
				headers: {
				  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
				},
				})
			  .then((response) => { 
						console.log("Resp:");
						console.log(response);
						if(response.data.status===1 || response.data.status==="1")
						{
							//alert("Success");
							this.setState({dnsData:response.data.resData});
						}
						else {alert("Sorry:"+response.data.msg);
						}
						this.setState({submitStatus:true});
			  }
			  )
			  .catch((error) => { 
				this.showError(error);
				this.setState({submitStatus:true});
				}
			 );
	 
	 
	 }
	 showError=(error)=>
	  {   
		  console.dir(error);
		  this.setState({error:"Sorry! Something went wrong. Please try again later"});
		
	  };
	  prepareTable=(data)=>
	  {
		  return(
				<table className='table'>
				{data.map((rec) => {		if(rec.type==="A" || rec.type==="AAAA")
											return (													
													<tr>
														<td>{rec.type}</td><td>{rec.address}</td><td>TTL:{rec.ttl}</td>
													</tr>
													)
											else if(rec.type==="MX")
											return (													
													<tr>
														<td>{rec.type}</td><td>{rec.exchange}</td><td></td>
													</tr>
													)
											else if(rec.type==="NS")
											return (													
													<tr>
														<td>{rec.type}</td><td>{rec.value}</td>
													</tr>
													)
											else if(rec.type==="SOA")
											return (													
													<tr>
														<td>{rec.type}</td><td>{"ns-name:"+rec.nsname+", hostmaster:"+rec.hostmaster}</td>
														<td>TTL:{rec.minttl}</td>
													</tr>
													)
											else if(rec.type==="TXT")
											return (
													
													<tr>
														<td>{rec.type}</td><td>
															{rec.entries[0]}
														</td>
														<td></td>
													</tr>
													)		
				})}
				</table>
		  );
		 
	  }
	  showTimer=()=>
	  {
		  return(<img src={waitGif} style={{width:60,height:60}} />);
	  }
	 render()
	{
		return(
		<div className="container">
		  <div style={{paddingTop:120}}>&nbsp;</div>
		  <div className="pull-right">
			<Nav.Link href="#/adddomain" >Add Domain</Nav.Link>
			
		  </div>	
		  <h2>DNS Record</h2>
		  <form action="/action_page.php">
			<div className="form-group">
			  <label for="email">Enter Domain</label>
			  <input type="text" onChange={(event)=>this.setState({domain:event.target.value })} className="form-control" id="domain" placeholder="example.com" name="domain"/>
			   <label className='form-text text-muted'>&nbsp;&nbsp;&nbsp;&nbsp;</label>	
			</div>
			<div>
			{this.state.error && <Alert variant="danger">!{this.state.error}</Alert>}
			</div>
			<button type="submit" className="btn btn-primary pull-right" onClick={this.submitHandler} disabled={!this.state.submitStatus}>Fetch</button>
		  </form>
		  <div>
		    {!this.state.submitStatus && this.showTimer() }
			{this.state.dnsData && this.prepareTable(this.state.dnsData) }
		  </div>
		</div>
		);
	}
};

export default DNSRecords;