import React, { Component } from "react";
import {Alert,Nav} from "react-bootstrap";
import axiosInstance from "./utils/axios";
import waitGif from "./timer.gif";
class AddDomain extends Component {
  
  
	
	 constructor(props)  {
		super(props);
		this.state={
			headerTitle:{fontSize:"46px",fontFamily: "Arial", textAlign:"center"},
			domainName:'',
			ip:'',
			error:null,
			submitStatus:true
			
		};
	 }
	 submitHandler = (e) => {
		e.preventDefault();
		let formData = new FormData();
		
		formData.append("domainName", "jilani.com");
		formData.append("recordDomain", this.state.domain);
		formData.append("recordIP", this.state.ip);
		formData.append("customerId", "50138461");
		
		/*var object = {};
		formData.forEach((value, key) => object[key] = value);
		var jsonData = JSON.stringify(object); console.log(jsonData);*/
		var object ="vi=2";
		formData.forEach((value, key) => object=object+"&"+key+"="+value);
		console.log(object);
		this.setState({dnsData:null});
		this.setState({submitStatus:false});
		//.post("http://10.160.0.4/action/get/dnsrecords", object, {
		 axiosInstance
				.post("./dns/add/domain", object, {
				headers: {
				  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
				},
				})
			  .then((response) => { 
						console.log("Resp:");
						console.log(response);
						if(response.data.status===1 || response.data.status==="1")
						{
							alert("Success");
							this.setState({dnsData:response.data.resData});
						}
						else {alert("Sorry:"+response.data.msg);
						}
						this.setState({submitStatus:true});
			  }
			  )
			  .catch((error) => { 
				this.showError(error);
				this.setState({submitStatus:true});
				}
			 );
	 
	 
	 }
	 showError=(error)=>
	  {   
		  console.dir(error);
		  this.setState({error:"Sorry! Something went wrong. Please try again later"});
		
	  };
	showTimer=()=>
	  {
		  return(<img src={waitGif} style={{width:60,height:60}} />);
	  }  
	 render()
	{
		return(
		<div className="container">
		  <div style={{paddingTop:120}}>&nbsp;</div>
		   <div className="pull-right">
			<Nav.Link href="#/drecs" >DNS Records</Nav.Link>
		   </div>	
		  <h2>(+) New Domain</h2>
		  <form action="/action_page.php">
			<div className="form-group">
			  <label for="email">Enter Domain</label>
			  <input type="text" onChange={(event)=>this.setState({domain:event.target.value })} className="form-control" id="domain" placeholder="example.com" name="domain"/>
			   <label className='form-text text-muted'>&nbsp;&nbsp;&nbsp;&nbsp;</label>	
			</div>
			<div className="form-group">
			  <label for="email">IP Address</label>
			  <input type="text" onChange={(event)=>this.setState({ip:event.target.value })} className="form-control" id="ip" placeholder="0.0.0.0" name="ip"/>
			   <label className='form-text text-muted'>&nbsp;&nbsp;&nbsp;&nbsp;</label>	
			</div>
			<div>
			{!this.state.submitStatus && this.showTimer() }
			{this.state.error && <Alert variant="danger">!{this.state.error}</Alert>}
			</div>
			<button type="submit" className="btn btn-primary pull-right" onClick={this.submitHandler} disabled={!this.state.submitStatus}>Submit</button>
		  </form>
		  
		</div>
		);
	}
};

export default AddDomain;